
import { format, newDate, endOfMonth, addDays } from 'date-fns-jalali'

const persian = {

	newDate(persianDate)
	{
		if (!persianDate)
		{
			return new Date()
		}

		const pieces =
			persianDate.split('/');

		const year = Number(pieces[0])
		const month = Number(pieces[1]) - 1
		const day = Number(pieces[2])

		return newDate(year, month, day)
	},


	now(customFormat)
	{
		return format(persian.newDate(), customFormat || 'yyyy/MM/dd')
	},

	addDays(persianDate, amount, customFormat)
	{
		persianDate = persianDate || persian.now();

		const date = persian.newDate(persianDate);
		const add = addDays(date, amount);
		return format(add, customFormat || 'yyyy/MM/dd')
	},

	endOfMonth(persianDate, customFormat)
	{
		const date = persian.newDate(persianDate);
		const end = endOfMonth(date);
		return format(end, customFormat || 'yyyy/MM/dd')
	},

	format(persianDate, customFormat)
	{
		const date = persian.newDate(persianDate);
		return format(date, customFormat || 'yyyy/MM/dd')
	},

	word(persianDate, customFormat)
	{
		const date = persian.newDate(persianDate);
		return format(date, customFormat || 'cccc d MMMM yyyy');
	},

	dayName(persianDate)
	{
		const date = persian.newDate(persianDate);
		return format(date,'cccc');
	},

	time: function ()
	{
		const date = new Date();

		var h = date.getHours()
		var m = date.getMinutes()
		var s = date.getSeconds()

		return `${(h >= 10 ? h : `0${h}`)}:${(m >= 10 ? m : `0${m}`)}:${ (s >= 10 ? s : `0${s}`)}`
	},

	cleanTime: function ()
	{
		const date = new Date();
		return `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
	},

	time7: function ()
	{
		const date = new Date();

		var h = date.getHours()
		var m = date.getMinutes()

		return `${(h >= 10 ? h : `0${h}`)}:${(m >= 10 ? m : `0${m}`) }`
	},

	cleanDateTime()
	{
		const date = persian.newDate();
		return `${format(date, 'yyyyMMdd')}${persian.cleanTime()}`;
	},

	addTimes: (times) =>
	{
		const z = (n) => (n < 10 ? '0' : '') + n;

		let hour = 0
		let minute = 0
		let second = 0
		for (const time of times)
		{
			const splited = time.split(':');
			hour += parseInt(splited[0]);
			minute += parseInt(splited[1])

			if (splited[2])
			{
				second += parseInt(splited[2])
			}

		}
		const seconds = second % 60
		const minutes = parseInt(minute % 60) + parseInt(second / 60)
		const hours = hour + parseInt(minute / 60)

		if (seconds > 0)
		{
			return z(hours) + ':' + z(minutes) + ':' + z(seconds)
		}

		return z(hours) + ':' + z(minutes)
	},

	ininHalfSpace(text)
	{
		if (true)
		{

		}
		return 
	}
}

export default persian;
