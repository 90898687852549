// initial state
const state = () => ({
	socketIsConnect: false,
	socketError: null,
	servers: [],
	socket: {},
	rsaPublicKey: null,
	rsaPrivateKey: null,
	aesKey:null
})

// getters
const getters = {
}

// actions
const actions = {
	setServers({ commit }, servers)
	{
		commit('setServers', servers);
	},

	setSocket({ commit }, arg)
	{
		commit('setSocket', arg);
	},

	setRsaPublicKey({ commit }, value)
	{
		commit('setRsaPublicKey', value);
	},

	setRsaPrivateKey({ commit }, value)
	{
		commit('setRsaPrivateKey', value);
	},

	setAesKey({ commit }, value)
	{
		commit('setAesKey', value);
	},

	clearSocket({ commit })
	{
		commit('clearSocket');
	}
}

// mutations
const mutations =
{
	setServers(state, servers)
	{
		state.servers = servers;
	},

	setSocket(state, arg)
	{
		state.socket[arg.serverName] = arg.connection;
	},

	setRsaPublicKey(state, value)
	{
		state.rsaPublicKey = value;
	},

	setRsaPrivateKey(state, value)
	{
		state.rsaPrivateKey = value;
	},

	setAesKey(state, value)
	{
		state.aesKey = value;
	},

	clearSocket(state)
	{
		const keys =
			Object.keys(state.socket);

		for (var i = 0; i < keys.length; i++)
		{
			try
			{
				state.socket[keys[i]].stop()
			} catch (e) { }
		}

		state.socket = {};
		window.$keys.ws = {}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
